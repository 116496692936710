import React, { Component } from "react";
import Head from "./Head";
import Hero from "./Hero";
import ThreePanel from "./ThreePanel";
import Carousel from "./Carousel";
import Contact from "./Contact";
import Hours from "./Hours";
import _ from "lodash";
import { app } from "../services/flamelink";

export default class About extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    app.content
      .get("about", {
        populate: true
      })
      .then(data =>
        this.setState({
          data: data
        })
      );
  }

  renderTimeline() {
    const section1 = this.state.data.section1;
    if (section1) {
      return _.map(section1.timeline, timeline => {
        return (
          <div className="timeline">
            <h3>Celebrating 100 Years</h3>
            <img src={timeline.url} alt="Timeline" />
          </div>
        );
      });
    }
  }

  renderMission() {
    const data = this.state.data.section1;
    if (data) {
      return <div className="quote">{data.mission}</div>;
    }
  }

  head() {
    if (this.props.siteTitle) {
      return <Head pageTitle="About" siteTitle={this.props.siteTitle} />;
    }
  }

  render() {
    return (
      <div>
        {this.head()}
        <Hero
          arrow={true}
          arrowText="Learn More"
          type="video"
          playButton={true}
          data={this.state.data.hero}
        />
        {this.renderTimeline()}
        <div className="mission">{this.renderMission()}</div>
        <ThreePanel type="image" data={this.state.data.threeColumnGrid} />
        <Carousel data={this.state.data.carousel} />
        <Hours info={this.props.info} />
        <Contact padding="100px 0" image={this.props.image} />
      </div>
    );
  }
}

import React, { Component } from "react";
import _ from "lodash";

export default class FourGrid extends Component {
  renderImageGrid() {
    const data = this.props.data;
    if (data) {
      return _.map(data, index => {
        return _.map(index.image, image => {
          return (
            <div
              className={
                this.props.text ? "four-grid-item aligned" : "four-grid-item"
              }
            >
              <div
                className="four-grid-photo"
                style={{ backgroundImage: "url(" + image.url + ")" }}
              >
                <div className="item-container">
                  <h2>{index.textOverlay}</h2>
                </div>
              </div>
              {this.props.text && <h3>{index.imageTitle}</h3>}
            </div>
          );
        });
      });
    }
  }
  render() {
    return (
      <div className="four-grid">
        <div className="flex-container">{this.renderImageGrid()}</div>
      </div>
    );
  }
}

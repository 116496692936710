import React, { Component } from "react";
import _ from "lodash";

export default class OneHalfImageImage extends Component {
  renderSection() {
    const data = this.props.data;
    if (data) {
      return _.map(data.image1, image1 => {
        return _.map(data.image2, image2 => {
          return (
            <div className="one-half">
              <div className="feature-text">
                <h2>{data.headline}</h2>
                <p>{data.subheadline}</p>
              </div>
              <div
                className="left"
                style={{ backgroundImage: "url(" + image1.url + ")" }}
              />
              <div
                className="right"
                style={{ backgroundImage: "url(" + image2.url + ")" }}
              />
            </div>
          );
        });
      });
    }
  }
  render() {
    return <div>{this.renderSection()}</div>;
  }
}

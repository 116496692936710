import React, { Component } from "react";
import _ from "lodash";

export default class ThreePanel extends Component {
  renderHeadline() {
    const data = this.props.data;
    if (this.props.headline && data) {
      return (
        <div className="headline">
          <h3>{data.subtext}</h3>
          <h2>{data.headline}</h2>
        </div>
      );
    }
  }
  renderPanels() {
    const data = this.props.data;
    if (data && this.props.type === "icon") {
      return (
        <div className="flex-container">
          <div className="icon-container">
            {_.map(data.block1Icon, image => {
              return <img src={image.url} alt="Custom Tailoring" />;
            })}
            <div dangerouslySetInnerHTML={{ __html: data.block1Text }} />
          </div>
          <div className="icon-container">
            {_.map(data.block2Icon, image => {
              return <img src={image.url} alt="Custom Tailoring" />;
            })}
            <div dangerouslySetInnerHTML={{ __html: data.block2Text }} />
          </div>
          <div className="icon-container">
            {_.map(data.block3Icon, image => {
              return <img src={image.url} alt="Custom Tailoring" />;
            })}
            <div dangerouslySetInnerHTML={{ __html: data.block3Text }} />
          </div>
        </div>
      );
    }
    if (data && this.props.type === "image") {
      return (
        <div className="flex-container">
          {_.map(data, index => {
            return _.map(index.image, image => {
              return (
                <div className="icon-container">
                  <img
                    style={{ height: "initial" }}
                    src={image.url}
                    alt="Custom Tailoring"
                  />

                  <div dangerouslySetInnerHTML={{ __html: index.content }} />
                </div>
              );
            });
          })}
        </div>
      );
    }
  }
  render() {
    return (
      <div style={{ background: this.props.bgColor }} className="three-panel">
        {this.renderHeadline()}
        {this.renderPanels()}
      </div>
    );
  }
}

import React, { Component } from "react";
import _ from "lodash";
export default class FourGridMosaic extends Component {
  renderImage(index) {
    return _.map(this.props.data[index].backgroundImage, image => {
      return image.url;
    });
  }
  renderSection() {
    const data = this.props.data;
    if (data) {
      return (
        <div className="columns">
          <div
            className="column grid-box full-height"
            style={{
              backgroundImage: "url(" + this.renderImage(0) + ")"
            }}
          >
            <div className="box-container">
              <h2>{data[0].textOverlay}</h2>
              <a href={data[0].buttonUrl} className="btn-black">
                Learn More
              </a>
            </div>
          </div>
          <div className="column" style={{ padding: "0" }}>
            <div className="flex-container">
              <div
                className="top grid-box"
                style={{
                  backgroundImage: "url(" + this.renderImage(1) + ")"
                }}
              >
                <div className="box-container">
                  <h2>{data[1].textOverlay}</h2>
                  <a href={data[1].buttonUrl} className="btn-black">
                    Learn More
                  </a>
                </div>
              </div>
              <div
                className="bottom grid-box"
                style={{
                  backgroundImage: "url(" + this.renderImage(2) + ")"
                }}
              >
                <div className="box-container">
                  <h2>{data[2].textOverlay}</h2>
                  <a href={data[2].buttonUrl} className="btn-black">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    return <div className="four-col-moasic">{this.renderSection()}</div>;
  }
}

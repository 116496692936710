import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false
    };
  }
  render() {
    return (
      <Helmet>
        <meta charSet="utf-8" />

        <title>Richard Bennett Tailors | {this.props.pageTitle}</title>
        <link rel="canonical" href={this.props.url} />
        <meta name="description" content={this.props.des} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={this.props.url} />
        <meta property="og:title" content={"Hint - " + this.props.mTitle} />
        <meta property="og:description" content={this.props.des} />
        <meta property="og:image" content={this.props.ogImage} />
        <meta property="og:image:url" content={this.props.ogImage} />
      </Helmet>
    );
  }
}

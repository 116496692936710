import React, { Component } from "react";
import logo from "../assets/logo.svg";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <ul className="follow">
            <li>
              <h3>Follow Us:</h3>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.social.facebook}
              >
                <i className="fab fa-facebook" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.social.instagram}
              >
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
          <img src={logo} alt={this.props.siteTitle} />
          <p>
            <strong>{this.props.siteTitle}</strong> All Rights Reserved.
          </p>
        </div>
      </footer>
    );
  }
}

import React, { Component } from "react";
import _ from "lodash";

export default class OneHalfImageText extends Component {
  renderMainSection() {
    const data = this.props.data;
    if (data) {
      return (
        <div className="two-col-left">
          {_.map(data.image, image => {
            return (
              <div
                className="left"
                // style={{ backgroundImage: "url(" + image.url + ")" }}
              >
                <img src={image.url} alt={data.title} />
              </div>
            );
          })}
          <div className="right">
            <div className="right-container">
              <h4>{data.subtext}</h4>
              <h2>{data.title}</h2>
              <p>{data.content}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return <div>{this.renderMainSection()}</div>;
  }
}

import React, { Component } from "react";

import { app } from "../services/flamelink";
import Hero from "./Hero";
import OneThirdTextImage from "./OneThirdTextImage";
import Head from "./Head";
import OneHalfImageImage from "./OneHalfImageImage";
import OneHalfImageText from "./OneHalfImageText";
import Contact from "./Contact";
import ScrollableAnchor from "react-scrollable-anchor";

export default class Custom extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    app.content
      .get("custom", {
        populate: true
      })
      .then(data =>
        this.setState({
          data: data
        })
      );
  }

  head() {
    if (this.props.siteTitle) {
      return <Head pageTitle="Custom" siteTitle={this.props.siteTitle} />;
    }
  }

  render() {
    return (
      <div className="custom">
        {this.head()}
        <Hero
          arrow={true}
          arrowText="See More"
          type="image"
          data={this.state.data.hero}
        />
        <ScrollableAnchor id={"section1"}>
          <OneThirdTextImage data={this.state.data.section1} />
        </ScrollableAnchor>

        <OneHalfImageImage data={this.state.data.feature1} />
        <OneHalfImageText data={this.state.data.section2} />
        <Contact
          image={this.props.image}
          logos={this.props.logos}
          logoType={this.props.match.params.id}
        />
      </div>
    );
  }
}

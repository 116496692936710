import React, { Component } from "react";
import Slider from "react-slick";
import _ from "lodash";

export default class Carousel extends Component {
  renderSlide() {
    const data = this.props.data;
    if (data) {
      return _.map(data, index => {
        return _.map(index.backgroundImage, image => {
          return (
            <div>
              <div
                className="carousel-slide"
                style={{ backgroundImage: "url(" + image.url + ")" }}
              >
                <div className="caption">
                  <h3>{index.title}</h3>
                  <p>{index.content}</p>
                </div>
              </div>
            </div>
          );
        });
      });
    }
  }
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      centerPadding: "60px",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>{this.renderSlide()}</Slider>
      </div>
    );
  }
}

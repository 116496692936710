import React, { Component } from "react";
import JotformEmbed from "react-jotform-embed";
import _ from "lodash";
import LogoGrid from "./LogoGrid";

export default class Contact extends Component {
  renderImage() {
    const image = this.props.image;
    if (image) {
      return _.map(image, index => {
        return index.url;
      });
    }
  }

  renderContact() {
    if (this.props.logos) {
      return <LogoGrid data={this.props.logos} />;
    }
  }
  render() {
    return (
      <div className="contact">
        {this.renderContact()}
        <div className="flex-container" style={{ padding: this.props.padding }}>
          <div
            className="contact-image"
            style={{ backgroundImage: "url(" + this.renderImage() + ")" }}
          />
          <div className="c-form">
            <h4>Have a Question for Us?</h4>
            <h2>Contact Us</h2>
            <JotformEmbed src="https://form.jotform.com/83445787357169" />
          </div>
        </div>
      </div>
    );
  }
}

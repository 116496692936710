import React, { Component } from "react";
import videoBg from "../assets/vintage-arrangement.jpg";
import _ from "lodash";
import ReactImageVideoLightbox from "react-image-video-lightbox";

export default class TwoColVideo extends Component {
  constructor() {
    super();
    this.state = { lightboxOpen: false };
  }

  renderModal() {
    if (this.state.lightboxOpen) {
      return (
        <div className="lightbox-modal">
          <div
            className="closer"
            onClick={() => this.setState({ lightboxOpen: false })}
          >
            <i className="fal fa-times" />
          </div>
          <ReactImageVideoLightbox
            data={[
              {
                url:
                  "https://player.vimeo.com/video/307094288?autoplay=1&byline=0&title=0",
                type: "video",
                altTag: "some video"
              }
            ]}
            startIndex={0}
            showResourceCount={false}
            onCloseCallback={() => {
              this.setState({ lightboxOpen: false });
            }}
          />
        </div>
      );
    }
  }
  renderSection() {
    const data = this.props.data;
    if (data) {
      return (
        <div className="flex-container">
          {this.renderModal()}
          <div className="video-col">
            <div className="video-embed">
              {_.map(data.videoThumbnail, image => {
                return (
                  <div
                    className="video-embed"
                    style={{ backgroundImage: "url(" + image.url + ")" }}
                  >
                    <div className="center-button">
                      <div
                        onClick={() => {
                          this.setState({ lightboxOpen: true });
                        }}
                        className="btn-black"
                      >
                        watch <i className="fas fa-play" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="embed-background">
              <img src={videoBg} alt="Video Background" />
            </div>
          </div>
          <div className="text-col">
            <div className="col-container">
              <h4 className="flourish">{data.sectionTitle}</h4>
              <h2>{data.headline}</h2>
              <p>{data.sectionCopy}</p>
              <a href={data.buttonUrl} className="btn-black">
                {data.buttonText}
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    return <div className="two-col-video">{this.renderSection()}</div>;
  }
}

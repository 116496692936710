import React, { Component } from "react";

import _ from "lodash";

export default class OneThirdTextImage extends Component {
  renderSection() {
    const data = this.props.data;
    if (data) {
      return _.map(data.image, image => {
        return (
          <div className="one-third">
            <div className="left">
              <div className="left-container">
                <h4>{data.subtext}</h4>
                <h2>{data.headline}</h2>
                <p>{data.subheadline}</p>
              </div>
            </div>
            <div
              className="right"
              style={{ backgroundImage: "url(" + image.url + ")" }}
            />
          </div>
        );
      });
    }
  }
  render() {
    return <div>{this.renderSection()}</div>;
  }
}

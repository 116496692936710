import React, { Component } from "react";
import _ from "lodash";

import ReactImageVideoLightbox from "react-image-video-lightbox";
import arrow from "../assets/arrow.svg";
import frame from "../assets/frame.png";
export default class Hero extends Component {
  constructor() {
    super();
    this.state = { lightboxOpen: false };
  }

  renderButton() {
    const play = this.props.playButton;
    if (play) {
      return (
        <div
          onClick={() => {
            this.setState({ lightboxOpen: true });
          }}
          className="btn-black"
        >
          watch <i className="fas fa-play" />
        </div>
      );
    }
  }

  renderModal() {
    const play = this.props.playButton;
    if (play && this.state.lightboxOpen) {
      return (
        <div className="lightbox-modal">
          <div
            className="closer"
            onClick={() => this.setState({ lightboxOpen: false })}
          >
            <i className="fal fa-times" />
          </div>
          <ReactImageVideoLightbox
            data={[
              {
                url:
                  "https://player.vimeo.com/video/307094288?autoplay=1&byline=0&title=0",
                type: "video",
                altTag: "some video"
              }
            ]}
            startIndex={0}
            showResourceCount={false}
            onCloseCallback={() => {
              this.setState({
                lightboxOpen: false
              });
            }}
          />
        </div>
      );
    }
  }

  renderBackground() {
    if (this.props.type === "video") {
      return (
        <div class="vimeo-wrapper">
          <div className="frame">
            <img src={frame} alt="frame" />
          </div>
          <div className="overlay" />
          <iframe
            src="https://player.vimeo.com/video/306425146?background=1&autoplay=1&loop=1&byline=0&title=0"
            title="Richard Bennett"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        </div>
      );
    } else if (this.props.type === "image" && this.props.data) {
      return _.map(this.props.data.image, hero => {
        console.log(hero.url);
        return (
          <div
            className="image-wrapper"
            style={{
              backgroundImage: "url(" + hero.url + ")"
            }}
          >
            <div className="frame">
              <img src={frame} alt="frame" />
            </div>
            <div className="overlay" />
          </div>
        );
      });
    }
  }

  renderCopy() {
    const hero = this.props.data;
    if (hero) {
      return (
        <div>
          {this.renderModal()}
          <div className="intro">
            <h1>{hero.headline}</h1>
            <p>{hero.subheadline}</p>
            {this.renderButton()}
          </div>
        </div>
      );
    }
  }

  renderArrow() {
    if (this.props.arrow) {
      return (
        <div className="arrow">
          <p>{this.props.arrowText}</p>
          <img src={arrow} alt="Scroll for me" />
        </div>
      );
    }
  }
  render() {
    return (
      <div className="hero">
        {this.renderBackground()}
        {this.renderCopy()}
        {this.renderArrow()}
      </div>
    );
  }
}

import React, { Component } from "react";

import _ from "lodash";

export default class LogoGrid extends Component {
  renderLogos() {
    const brandLogos = this.props.data;
    if (brandLogos) {
      return _.map(brandLogos.logos, index => {
        return _.map(index.logo, image => {
          return _.map(index.category, cat => {
            return (
              <div className="logo-item">
                <img src={image.url} alt="Logo" />
              </div>
            );
          });
        });
      });
    }
  }

  render() {
    return (
      <div className="logo-grid" style={{ padding: this.props.padding }}>
        <div className="flex-container">
          <h3 className="section-headline">The Best Brands</h3>
          {this.renderLogos()}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Promo from "./components/Promo";
import Hours from "./components/Hours";
import Contact from "./components/Contact";
import Page from "./components/Page";
import Custom from "./components/Custom";
import { app } from "./services/flamelink";

import { CSSTransition, TransitionGroup } from "react-transition-group";

class App extends Component {
  constructor() {
    super();
    this.state = {
      siteInfo: "",
      brandLogos: [],
      currentDay: "",
      loading: true,
      showHours: true,
      social: []
    };
  }

  componentDidMount() {
    const d = new Date();
    const n = d.getDay();
    app.content
      .get("brandLogos", {
        populate: true
      })
      .then(brandLogos =>
        this.setState({
          brandLogos: brandLogos
        })
      );

    this.setState({
      currentDay: n - 1
    });

    app.content
      .get("siteInfo", {
        populate: true
      })
      .then(siteInfo =>
        this.setState({
          siteInfo: siteInfo
        })
      );

    app.content.get("socialMedia").then(social =>
      this.setState({
        social: social
      })
    );
  }

  adjustHours(page) {
    if (page) {
      this.setState({
        showHours: false
      });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <NavBar
            phone={this.state.siteInfo.phone}
            hours={this.state.siteInfo.hours}
            day={this.state.currentDay}
          />
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <Home
                          logos={this.state.brandLogos}
                          image={this.state.siteInfo.contactImage}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/promo/:id"
                      render={props => (
                        <Promo
                          image={this.state.siteInfo.contactImage}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/about"
                      render={props => (
                        <About
                          image={this.state.siteInfo.contactImage}
                          info={this.state.siteInfo}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/hours"
                      render={props => (
                        <Hours
                          topbar={true}
                          margin={true}
                          info={this.state.siteInfo}
                          image={this.state.siteInfo.contactImage}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/contact"
                      render={props => (
                        <Contact
                          image={this.state.siteInfo.contactImage}
                          logos={this.state.brandLogos}
                          info={this.state.siteInfo}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/custom"
                      render={props => (
                        <Custom
                          image={this.state.siteInfo.contactImage}
                          logos={this.state.brandLogos}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/p/:id"
                      render={props => (
                        <Page
                          image={this.state.siteInfo.contactImage}
                          logos={this.state.brandLogos}
                          siteTitle={this.state.siteInfo.siteTitle}
                          {...props}
                        />
                      )}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
          <Footer social={this.state.social} title={this.state.siteInfo} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

import React, { Component } from "react";
import _ from "lodash";
import Head from "./Head";

export default class Hours extends Component {
  renderHours() {
    const info = this.props.info;
    if (info) {
      return _.map(info.hours, data => {
        return (
          <li>
            <div>{data.day}</div>
            <div>{data.hours}</div>
          </li>
        );
      });
    }
  }
  renderMap() {
    const info = this.props.info;
    if (info) {
      return _.map(info.map, image => {
        return (
          <a
            href="https://goo.gl/GD5pTv"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={image.url} alt="Map" />
          </a>
        );
      });
    }
  }

  head() {
    if (this.props.siteTitle) {
      return (
        <Head pageTitle="Hours & Location" siteTitle={this.props.siteTitle} />
      );
    }
  }

  render() {
    return (
      <div
        className={this.props.margin ? "location margin-adjust" : "location"}
      >
        {this.head()}
        {this.props.topbar && <div className="top-bar" />}
        <div className="flex-container">
          <div className="left">
            <h4>More Info</h4>
            <h2>Hours & Location</h2>
            <ul className="hours">{this.renderHours()}</ul>
            <ul className="more-info">
              <li>
                <div>
                  <i className="fas fa-phone" />
                </div>
                <div>
                  <a href={"tel:" + this.props.info.phone}>
                    {this.props.info.phone}
                  </a>
                </div>
              </li>
              <li>
                <div>
                  <i className="fas fa-map-marker-alt" />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.info.location
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="right">{this.renderMap()}</div>
        </div>
      </div>
    );
  }
}

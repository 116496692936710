import React, { Component } from "react";
import Head from "./Head";
import Hero from "./Hero";
import TwoColVideo from "./TwoColVideo";
import FourGridMosaic from "./FourGridMosaic";
import LogoGrid from "./LogoGrid";
import ThreePanel from "./ThreePanel";
import FourGrid from "./FourGrid";
import Contact from "./Contact";
import ScrollableAnchor from "react-scrollable-anchor";

import { app } from "../services/flamelink";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    app.content
      .get("home", {
        populate: true
      })
      .then(data =>
        this.setState({
          data: data
        })
      );
  }

  head() {
    if (this.props.siteTitle) {
      console.log(this.props.siteTitle.siteTitle);
      return <Head pageTitle="Home" siteTitle={this.props.siteTitle} />;
    }
  }

  render() {
    return (
      <div>
        {this.head()}
        <Hero
          arrowText="Explore"
          arrow={true}
          type="video"
          data={this.state.data.hero}
        />
        <ScrollableAnchor id={"section1"}>
          <TwoColVideo data={this.state.data.ourHistory} />
        </ScrollableAnchor>
        <FourGridMosaic data={this.state.data.threeImageGrid} />
        <LogoGrid padding="50px 0" data={this.props.logos} />
        <ThreePanel
          headline={true}
          bgColor="#f2f2f2"
          type="icon"
          data={this.state.data.iconSection}
        />
        <div className="info-section">
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.data.fourGridContent
            }}
            className="info"
          />
          <FourGrid data={this.state.data.fourGridImages} />
        </div>
        <Contact padding="100px 0" image={this.props.image} />
      </div>
    );
  }
}

import React, { Component } from "react";

import { app } from "../services/flamelink";

import Hero from "./Hero";
import ThreeGrid from "./ThreeGrid";
import FourGrid from "./FourGrid";
import OneHalfImageText from "./OneHalfImageText";
import Contact from "./Contact";
import Head from "./Head";
import ScrollableAnchor from "react-scrollable-anchor";

export default class Page extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    app.content
      .get(this.props.match.params.id, { populate: true })
      .then(data => this.setState({ data: data }));
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  head() {
    if (this.props.siteTitle) {
      return (
        <Head
          pageTitle={this.capitalize(this.props.match.params.id)}
          siteTitle={this.props.siteTitle}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.head()}
        <Hero
          arrowText="See More"
          arrow={true}
          type="image"
          data={this.state.data.hero}
        />
        <ScrollableAnchor id={"section1"}>
          <ThreeGrid data={this.state.data.imageGrid} />
        </ScrollableAnchor>

        <OneHalfImageText data={this.state.data.mainContent} />
        <FourGrid text={true} data={this.state.data.imageGrid2} />
        <Contact
          image={this.props.image}
          logos={this.props.logos}
          logoType={this.props.match.params.id}
        />
      </div>
    );
  }
}

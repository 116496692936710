import flamelink from "flamelink";

export const app = flamelink({
  apiKey: "AIzaSyAlXuCHdR82e97qHKYaCWf51_eGIPe_x-M",
  authDomain: "richard-bennett-tailors.firebaseapp.com",
  databaseURL: "https://richard-bennett-tailors.firebaseio.com",
  projectId: "richard-bennett-tailors",
  storageBucket: "richard-bennett-tailors.appspot.com",
  messagingSenderId: "462795075152"
});

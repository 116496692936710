import React, { Component } from "react";

import _ from "lodash";
import Head from "./Head";
import { app } from "../services/flamelink";

export default class Promo extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { id } = this.props.match.params;

    app.content
      .getByField("promotions", "url", id, {
        populate: true
      })
      .then(data =>
        this.setState({
          data: Object.values(data)
        })
      );
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  head() {
    if (this.props.siteTitle) {
      return (
        <Head
          pageTitle={this.capitalize(this.props.match.params.id)}
          siteTitle={this.props.siteTitle}
        />
      );
    }
  }

  render() {
    return (
      <div className="promo margin-adjust">
        {this.head()}
        <div className="top-bar" />
        {_.map(this.state.data, data => {
          return _.map(data.image, image => {
            return (
              <div className="flex-container">
                <div className="left">
                  <img src={image.url} alt={data.promotionTitle} />
                </div>
                <div className="right">
                  <h1>{data.promotionTitle}</h1>
                  <p>{data.content}</p>
                </div>
              </div>
            );
          });
        })}
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import _ from "lodash";
import { app } from "../services/flamelink";
import { slide as Menu } from "react-burger-menu";

export default class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      nav: [],
      isTop: true,
      menuOpen: false
    };
  }
  componentDidMount() {
    app.nav
      .get("mainMenu")
      .then(menus =>
        this.setState({
          nav: menus
        })
      )
      .catch(error =>
        console.error(
          "Something went wrong while retrieving the menus. Details:",
          error
        )
      );
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu(e) {
    e.preventDefault();
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  renderMobile() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
      >
        <div className="closer">
          <i className="fal fa-times" onClick={e => this.toggleMenu(e)} />
        </div>
        <Link onClick={e => this.toggleMenu(e)} to="/about">
          <h4>Hours/Location</h4>
        </Link>
        {_.map(this.state.nav.items, nav => {
          return (
            <Link key={nav.id} onClick={e => this.toggleMenu(e)} to={nav.url}>
              <h4>{nav.title}</h4>
            </Link>
          );
        })}
        {/* <div className="mobile-open">Open Today: {this.renderOpen()}</div> */}
      </Menu>
    );
  }

  renderNav() {
    return _.map(this.state.nav.items, nav => {
      return (
        <div key={nav.id} className="menu-item">
          <Link to={nav.url}>{nav.title}</Link>
        </div>
      );
    });
  }

  // renderOpen() {
  //   const hours = this.props.hours;
  //   let day = [];
  //
  //   if (hours) {
  //     day = Object.values(hours[this.props.day]);
  //     console.log(day[1]);
  //     return (
  //       <div>
  //         <div className="time">{day[1]}</div>
  //       </div>
  //     );
  //   }
  // }
  render() {
    return (
      <header> 
        <script src="https://cdn.polyfill.io/v2/polyfill.min.js?features=default,Array.prototype.includes" />

        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.1.0/css/all.css"
          integrity="sha384-87DrmpqHRiY8hPLIr7ByqhPIywuSsjuQAfMXAE0sMUpY3BM7nXjf+mLIUSvhDArs"
          crossOrigin="anonymous"
        />
        {this.renderMobile()}

        <div className="flex-container">
          <div className="left">
            <Link to="/">
              <img className="logo" src={logo} alt={this.props.siteTitle} />
            </Link>
          </div>
          <div className="right">
            {this.renderNav()}

            <div className="burger">
              <i className="far fa-bars" onClick={e => this.toggleMenu(e)} />
            </div>
            <div className="contact-blog">
              <div className="bg">
                <Link to="/hours">Hours/Location</Link>
                <a href={"tel:" + this.props.phone}>{this.props.phone}</a>
              </div>
              {/* <div className="open-rate">Open Today: {this.renderOpen()}</div> */}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

import React, { Component } from "react";
import _ from "lodash";
export default class ThreeGrid extends Component {
  renderImages() {
    const data = this.props.data;
    if (data) {
      return _.map(data, index => {
        return _.map(index.image, image => {
          return (
            <div
              className="three-grid-item"
              style={{ backgroundImage: "url(" + image.url + ")" }}
            />
          );
        });
      });
    }
  }
  render() {
    return <div className="three-grid">{this.renderImages()}</div>;
  }
}
